import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/State";
import { auth } from "@/store/modules/auth";
import { permission } from "@/store/modules/permission";

Vue.use(Vuex);

const options: StoreOptions<RootState> = {
    state: {},
    modules: {
        auth,
        permission
    }
};

export default new Vuex.Store(options);
