import { GetterTree } from "vuex";
import { RootState } from "@/store/State";
import { AuthState } from "./State";
import { tokenExpiration, getTokenIfNotExpired } from "@/helpers/OidcHelper";
import { Profile } from "oidc-client";

import { claimTypes } from "@/Constants";

export const authGetterTypes = {
    isAuthenticated: "auth/isAuthenticated",
    accessToken: "auth/accessToken",
    accessTokenExpiration: "auth/accessTokenExpiration",
    idToken: "auth/idToken",
    idTokenExpiration: "auth/idTokenExpiration",
    refreshToken: "auth/refreshToken",
    refreshTokenExpiration: "auth/refreshTokenExpiration",
    isChecked: "auth/isChecked",
    user: "auth/user",
    isImpersonating: "auth/isImpersonating",
    error: "auth/error"
};

const isAuthenticated = (state: AuthState): boolean => {
    return !!state.accessToken;
};

const getClaim = (user: Profile, claimType: string): string => {
    return user[claimType];
}

export const getters: GetterTree<AuthState, RootState> = {
    isAuthenticated(state: AuthState): boolean {
        return isAuthenticated(state);
    },
    accessToken(state: AuthState) {
        return getTokenIfNotExpired(state.accessToken);
    },
    accessTokenExpiration(state: AuthState) {
        return tokenExpiration(state.accessToken);
    },
    idToken(state: AuthState) {
        return getTokenIfNotExpired(state.idToken);
    },
    idTokenExpiration(state: AuthState) {
        return tokenExpiration(state.idToken);
    },
    refreshToken(state: AuthState) {
        return getTokenIfNotExpired(state.refreshToken);
    },
    refreshTokenExpiration(state: AuthState) {
        return tokenExpiration(state.refreshToken);
    },
    isChecked(state: AuthState) {
      return state.isChecked;
    },
    user(state: AuthState) {
        return state.user;
    },
    isImpersonating(state: AuthState) {
        return state.user && getClaim(state.user, claimTypes.actorSub) != null;
    },
    error(state: AuthState) {
        return state.error;
    }
};
