import { Guid } from "guid-typescript";

export enum ActionType {
    Add,
    Remove
}

export interface PermissionAction {
    id: Guid | null;
    resourcePermissionId: Guid;
    applicationId: Guid;
    type: ActionType;
}

export interface PermissionState {
    permisssionActions: PermissionAction[];
}
