import Vue from "vue";

export default (vue: typeof Vue) => {
  window.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      const modals = vue.prototype.$modal.context.root.__modalContainer.modals;
      if (modals?.length) {
        return;
      }

      vue.prototype.$modal.hide(modals[modals.length - 1].modalAttrs.name);
    }
  });
};
