import { ActionTree } from "vuex";
import { Guid } from "guid-typescript";
import { RootState } from "@/store/State";
import { PermissionState, PermissionAction, ActionType } from "./state";
import { types as mutationTypes } from "./mutations";

export const types = Object.freeze({
  addPermission: "addPermission",
  commitAction: "commitAction",
  removePermission: "removePermission",
  hasPermission: "hasPermission"
});

const getAction = (
  permissionActions: PermissionAction[],
  resourcePermissionId: Guid,
  applicationId: Guid) =>
    permissionActions.find((item) =>
      item.resourcePermissionId === resourcePermissionId &&
      item.applicationId === applicationId);

export const actions: ActionTree<PermissionState, RootState> = {
  addPermission(
    {dispatch},
    payload: {
      resourcePermissionId: Guid,
      applicationId: Guid
    }): void {
      dispatch(types.commitAction, {
        resourcePermissionId: payload.resourcePermissionId,
        applicationId: payload.applicationId,
        type: ActionType.Add
      });
  },
  removePermission(
    {dispatch},
    payload: {
      id: Guid,
      resourcePermissionId: Guid,
      applicationId: Guid
    }): void {
      dispatch(types.commitAction, {
        id: payload.id,
        resourcePermissionId: payload.resourcePermissionId,
        applicationId: payload.applicationId,
        type: ActionType.Remove
      });
  },
  commitAction({state, commit}, payload: PermissionAction) {
    const action = getAction(
      state.permisssionActions,
      payload.resourcePermissionId,
      payload.applicationId);

    if (action == null) {
      commit(mutationTypes.addAction, payload);
      return;
    }

    if (action.type === payload.type) {
      return;
    }

    commit(mutationTypes.removeAction, payload);
  },
  hasPermission(
    {state},
    payload: {
      resourcePermissionId: Guid,
      applicationId: Guid
  }): boolean | undefined  {
    const action = getAction(
      state.permisssionActions,
      payload.resourcePermissionId,
      payload.applicationId);

    return action != null
      ? action.type === ActionType.Add
      : undefined;
  }
};
