import { UserManager, User } from "oidc-client";
import AuthManagerSettings from "@/data/auth/settings/AuthSettings";
import SecureStorage from "@/data/auth/SecureStorage";
import { getCallbackPath } from "@/helpers/OidcHelper";
import { identityUrl, uiUrl } from "@/Config";

const defaultAuthManagerSettings: AuthManagerSettings = {
    userStore: new SecureStorage(),
    loadUserInfo: true
};

const sessionStorageKey = "auth-active-route";

export default class AuthManager {

    private static getAuthSettings(settings: AuthManagerSettings) {
        return {
            ...defaultAuthManagerSettings,
            ...settings,
            ...{ automaticSilentRenew: false }
        };
    }

    public readonly automaticSilentRenew?: boolean;
    public readonly callbackPath: string | null;
    private readonly settings: AuthManagerSettings;
    private readonly userManager: UserManager;

    constructor(settings: AuthManagerSettings) {
        this.settings = AuthManager.getAuthSettings(settings);
        this.userManager = new UserManager(this.settings);

        this.automaticSilentRenew = settings.automaticSilentRenew;
        this.callbackPath = getCallbackPath(settings.redirect_uri, uiUrl);
    }

    public saveActiveRoute(activeRoute: string) {
        sessionStorage.setItem(sessionStorageKey, activeRoute);
    }

    public getActiveRoute() {
        return sessionStorage.getItem(sessionStorageKey) ?? "/";
    }

    public clearSessionStorage() {
        sessionStorage.removeItem(sessionStorageKey);
    }

    public signinRedirect(args?: any) {
        return this.userManager.signinRedirect(args);
    }

    public signinSilent(args?: any) {
        return this.userManager.signinSilent(args);
    }

    public signinRedirectCallback(url?: string) {
        return this.userManager.signinRedirectCallback(url);
    }

    public addAccessTokenExpired(callback: () => void) {
        this.userManager.events.addAccessTokenExpired(callback);
    }

    public addAccessTokenExpiring(callback: () => void) {
        this.userManager.events.addAccessTokenExpiring(callback);
    }

    public storeUser(user: User) {
        return this.userManager.storeUser(user);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public getUserOrDefault(): Promise<User | null> {
        return new Promise<User|null>(resolve => {
            this.getUser()
                .then((user) => resolve(user))
                .catch(() => resolve(null));
        });
    }

    public signOut(args?: any) {
        return this.userManager.signoutRedirect(args);
    }

    public removeUser() {
        return this.userManager.removeUser();
    }

    public clearStaleState() {
        return this.userManager.clearStaleState();
    }

    public impersonate(email: string, retrunUrl?: string) {
        const impersonationUrl = new URL(`${identityUrl}/impersonate`);
        impersonationUrl.searchParams.append('email', email);
        impersonationUrl.searchParams.append('returnUrl', retrunUrl ?? window.location.origin);

        this.removeUser().then(() => {
            window.location.replace(impersonationUrl);
        });
    }

    public endImpersonation(retrunUrl?: string) {
        const impersonationUrl = new URL(`${identityUrl}/end-impersonation`);
        impersonationUrl.searchParams.append('returnUrl', retrunUrl ?? window.location.origin);

        this.removeUser().then(() => {
            window.location.replace(impersonationUrl);
        });
    }
}
