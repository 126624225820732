import Vue from "vue";
import {
  instanceOfXhrError,
  instanceOfIErrorViewer
} from "@/helpers/TypeUtils";

import {AxiosError} from "axios";

Vue.config.errorHandler = (err: Error, vm: Vue) => {
  if (instanceOfXhrError(err)) {
    let errorMessage = err.message;
    const axiosError = err as AxiosError<any>;
    if (axiosError?.response?.data?.message) {
      errorMessage += ":\r\n" + axiosError.response.data.message;
    }

    if (instanceOfIErrorViewer(vm)) {
      vm.error = errorMessage;
      vm.isLoading = false;
      return;
    }

    Vue.toasted.error(errorMessage);
    window.console.error(errorMessage, err);
  } else {
    window.console.error(err);
  }
};


