import { ActionTree } from "vuex";
import { RootState } from "@/store/State";
import { AuthState } from "./State";
import AuthManager from "@/data/auth/AuthManager";
import { Route } from "vue-router";
import {Profile, User} from "oidc-client";
import {
    OrganizationAdministratorRole,
    IdentityServerAdministratorRole,
    claimTypes
} from "@/Constants";

import { useGTM } from "@/Config"; 

import OrganizationService from "@/services/OrganizationService";

const isAuthenticated = (state: AuthState): boolean => {
    return !!state.accessToken;
};

const errorModel = (context: string, error: Error) => {
    return {
        context,
        error: error?.message ? error.message : error
    };
};

const checkForRole = (user: Profile, role: string): boolean => {
    return Array.isArray(user.role) &&
        user.role.some(item => item === role) ||
        user.role === role;
};

const getClaim = (user: Profile, claimType: string): string => {
    return user[claimType];
}

export const authActionTypes = {
    checkAssess: "auth/checkAssess",
    signinCallback: "auth/signinCallback",
    signOut: "auth/signOut",
    isAdmin: "auth/isAdmin",
    isOrganizationAdmin: "auth/isOrganizationAdmin",
    isIdentityAdmin: "auth/isIdentityAdmin",
    getOrganizationId: "auth/getOrganizationId",

    impersonate: "auth/impersonate",
    endImpersonation: "auth/endImpersonation",
};

const routeIsAuthCallback = (route: Route, authCallback: string) => {
  return route?.path?.replace(/\/$/, "").startsWith(authCallback);
};

export const makeActions = (authManager: AuthManager): ActionTree<AuthState, RootState> => ({
    async checkAssess({  state, dispatch }, route: Route): Promise<boolean> {
        if (routeIsAuthCallback(route, authManager.callbackPath || "/")) {
            return true;
        }

        const user = await authManager.getUserOrDefault();
        if (user && !user.expired) {
            dispatch("wasAuthenticated", user);

            const routeWithRole = route.matched.find(record => record.meta?.role);
            if (!routeWithRole) {
                return true;
            }

            const hasAccess = checkForRole(user.profile, routeWithRole.meta.role);
            return hasAccess;
        }

        if (isAuthenticated(state)) {
            dispatch("unsetAuth");
        }

        dispatch("authenticate", { redirectPath: route.fullPath });
        return false;
    },
    async authenticate({ commit }, args: any) {
        if (args instanceof String) {
            args = { redirectPath: args };
        }

        if (args.redirectPath) {
            authManager.saveActiveRoute(args.redirectPath);
        } else {
            authManager.clearSessionStorage();
        }

        try {
            return await authManager.signinRedirect();
        } catch (error: any) {
            commit("setError", errorModel("authenticate", error));
        }
    },
    async authenticateSilent({ commit, dispatch }, args?: any) {
        try {
            const user = await authManager.signinSilent(args);
            return await dispatch("wasAuthenticated", user);
        } catch (error: any) {
            commit("setError", errorModel("authenticateSilent", error));
            commit("setIsChecked");
        }
    },
    signinCallback({ commit, dispatch }, url: string) {
        return authManager.signinRedirectCallback(url)
            .then(user => {
                dispatch("wasAuthenticated", user);

                return authManager.getActiveRoute();
            }).catch(error => {
                commit("setError", errorModel("authenticateSilent", error));
                commit("setIsChecked");

                setTimeout(() => { throw error });
            });
    },
    wasAuthenticated({ state, commit, dispatch }, user) {
        dispatch("setAuth", user);
        if (!state.eventsAreBound) {
            authManager.addAccessTokenExpired(() => dispatch("unsetAuth"));
            if (authManager.automaticSilentRenew) {
                authManager.addAccessTokenExpiring(() => dispatch("authenticateSilent"));
            }

            commit("setEventsAreBound");
        }

        commit("setIsChecked");

    },
    async storeUser({ commit, dispatch }, user: User) {
        try {
            await authManager.storeUser(user);
            const oidcUser = await authManager.getUser();
            return await dispatch("wasAuthenticated", oidcUser);
        } catch (err: any) {
            commit("setError", errorModel("storeUser", err));
            commit("setIsChecked");
            throw err;
        }
    },
    async getUser({ dispatch }) {
        const user = await authManager.getUser();
        dispatch("setUser", user);
        return user;
    },
    async signOut({ dispatch }, args?: any) {
        await authManager.signOut(args);
        dispatch("unsetAuth");
    },
    async removeState({ dispatch }) {
        await authManager.removeUser();
        dispatch("unsetAuth");      
    },
    clearStaleState() {
        return authManager.clearStaleState();
    },
    isAdmin({ state }) {
        return state.user && (
            checkForRole(state.user, OrganizationAdministratorRole) ||
            checkForRole(state.user, IdentityServerAdministratorRole));
    },
    isOrganizationAdmin({ state }) {
        return state.user && checkForRole(state.user, OrganizationAdministratorRole);
    },
    isIdentityAdmin({ state }) {
        return state.user && checkForRole(state.user, IdentityServerAdministratorRole);
    },
    getOrganizationId({ state }) {
        if (!state.user) {
            return "";
        }

        return getClaim(state.user, claimTypes.organizationId);
    },
    async setAuth({commit}, user: User) {
        commit("setAuth", user);

        if (!useGTM) {
            return;
        }

        if (user.profile.sub === window.currentUser.userId) {
            return;
        }

        window.currentUser = {
            userId: user.profile.sub,
            userName: user.profile.name,
            familyName: user.profile.family_name,
            givenName: user.profile.given_name,
            idp: user.profile.idp
        };

        const fullName = user.profile.given_name && user.profile.family_name
            ? `${user.profile.given_name} ${user.profile.family_name}`
            : '';
        window.currentEmployee = {
            email: user.profile.name,
            fullName: fullName,
            uid: user.profile.sub
        };

        const tenant = await OrganizationService.getOrganizationTenant(user.profile.organization_id);
        window.currentOrganization = {
            organizationId: user.profile.organization_id,
            tenant: tenant
        };
    },
    unsetAuth({commit}) {
        commit("unsetAuth");

        if (!useGTM) {
            return;
        }

        window.currentUser = {};
        window.currentOrganization = {};

        window.currentEmployee = {};
    },
    impersonate({dispatch}, email: string) {
        authManager.impersonate(email);
    },
    endImpersonation({dispatch}) {
        authManager.endImpersonation();
    }
});
