import { Module } from "vuex";
import { RootState } from "@/store/State";
import { PermissionState } from "./state";
import { actions } from "./actions";
import { mutations} from "./mutations";
import { getters } from "./getters";

export { types as actionTypes } from "./actions";
export { types as getterTypes } from "./getters";
export { types as mutationTypes } from "./mutations";
export const moduleName = "permission";

export const permission: Module<PermissionState, RootState> = {
    namespaced: true,
    actions,
    mutations,
    getters,
    state: {
        permisssionActions: []
    }
};
