import { GetterTree } from "vuex";
import { Guid } from "guid-typescript";
import { RootState } from "@/store/State";
import { ActionType, PermissionState } from "./state";
import { AssignedPermissionDto } from "@/data/permissions/dto/assignedPermissionDto";

export const types = Object.freeze({
  permissionsChanged: "permissionsChanged",
  addedPermissions: "addedPermissions",
  removedPermissionIds: "removedPermissionIds"
});

export const getters: GetterTree<PermissionState, RootState> = {
  permissionsChanged(state: PermissionState): boolean {
    return state.permisssionActions.length !== 0;
  },
  addedPermissions(state: PermissionState): AssignedPermissionDto[] {
    return state.permisssionActions
      .filter((item) => item.type === ActionType.Add)
      .map((item) => ({
        resourcePermissionId: item.resourcePermissionId,
        applicationId: item.applicationId
      }));
  },
  removedPermissionIds(state: PermissionState): Guid[] {
    return state.permisssionActions
      .filter((item) => item.type === ActionType.Remove)
      .map(item => item.id as Guid);
  }
};
