
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { authActionTypes } from "@/store/modules/auth/Actions";

@Component({ name: "OidcCallback" })
export default class OidcCallback extends Vue {
  private created() {
    store.dispatch(authActionTypes.signinCallback)
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(error => {
        window.console.log(error);
        this.$router.push("/forbidden");
      });
  }
}
