import { render, staticRenderFns } from "./DropdownItem.vue?vue&type=template&id=d2988540&scoped=true&"
import script from "./DropdownItem.vue?vue&type=script&lang=ts&"
export * from "./DropdownItem.vue?vue&type=script&lang=ts&"
import style0 from "./DropdownItem.vue?vue&type=style&index=0&id=d2988540&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2988540",
  null
  
)

export default component.exports