import { MutationTree } from "vuex";
import { User } from "oidc-client";
import { AuthState } from "./State";

export const types = {
    setAuth: "setAuth",
    unsetAuth: "unsetAuth",
    setUser: "setUser",
    setIsChecked: "setIsChecked",
    setEventsAreBound: "setEventsAreBound",
    setError: "setError"
};

export const mutations: MutationTree<AuthState> = {
    setAuth(state: AuthState, user: User) {
        state.idToken = user.id_token;
        state.accessToken = user.access_token;
        state.refreshToken = user.refresh_token;
        state.user = user.profile;
        state.scopes = user.scopes;
        state.error = null;
    },
    unsetAuth(state: AuthState) {
        state.idToken = null;
        state.accessToken = null;
        state.refreshToken = undefined;
        state.user = null;
    },
    setUser(state: AuthState, user: User) {
        state.user = user.profile;
    },
    setIsChecked(state: AuthState) {
        state.isChecked = true;
    },
    setEventsAreBound(state: AuthState) {
        state.eventsAreBound = true;
    },
    setError(state: AuthState, model: { context: string, error: string | null }) {
        window.console.error(model.error);
        state.error = model.error;
    }
};
