export const OrganizationAdministratorRole = "OrganizationAdministrator";
export const IdentityServerAdministratorRole = "IdentityServerAdministrator";


export const claimTypes = Object.freeze({
  organizationId: 'organization_id',
  actorSub: 'actor_sub'
});

export const OrganizationIdClaimType = "organization_id";
