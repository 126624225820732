import { Store } from "vuex";
import { NavigationGuard } from "vue-router";
import { authActionTypes } from "@/store/modules/auth/Actions";

export default (store: Store<any>): NavigationGuard =>
    async (to, form, next) => {
        if (await store.dispatch(authActionTypes.checkAssess, to)) {
            next();
        }
    };
