
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "dropdown-item" })
export default class DropdownItem extends Vue {
  @Prop() private readonly icon?: string;

  private onClick() {
    this.$emit("click");
  }
}
