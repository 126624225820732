
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

import { useGTM } from "./Config"; 

@Component
export default class App extends Vue {
  public mounted() {
    let language;
    if (window.navigator.languages) {
      language = window.navigator.languages[0];
    } else {
      // @ts-ignore
      language = window.navigator.userLanguage || window.navigator.language;
    }

    moment.locale(language);

    this.addGtm();
  }

  private addGtm() {
    if (!useGTM) {
      return;
    }

    window.currentUser = {};
    window.currentOrganization = {};

    window.currentEmployee = {};
    window.currentCompany = {};

    const gtmScriptElement = document.createElement('script');
    gtmScriptElement.src = 'https://qualer.blob.core.windows.net/web-scripts/qualer-gtm.js';
    document.body.appendChild(gtmScriptElement);
  }
}
