import { Module } from "vuex";
import { RootState } from "@/store/State";
import { AuthState } from "@/store/modules/auth/State";
import { makeActions } from "@/store/modules/auth/Actions";
import { mutations} from "@/store/modules/auth/Mutations";
import { getters } from "@/store/modules/auth/Getters";
import AuthManager from "@/data/auth/AuthManager";
import { settings } from "@/data/auth/configs/oidcConfig";

export const auth: Module<AuthState, RootState> = {
    namespaced: true,
    actions: makeActions(new AuthManager(settings)),
    mutations,
    getters,
    state: {
        accessToken: null,
        idToken: null,
        refreshToken: undefined,
        user: null,
        scopes: null,
        isChecked: false,
        eventsAreBound: false,
        error: null
    }
};
