import { MutationTree } from "vuex";
import { PermissionState, PermissionAction } from "./state";
import { remove } from "@/helpers/ArrayHelper";

export const types = Object.freeze({
  addAction: "addAction",
  removeAction: "removeAction",
  clearActions: "clearActions"
});

export const mutations: MutationTree<PermissionState> = {
  addAction(state: PermissionState, action: PermissionAction) {
    state.permisssionActions.push(action);
  },
  removeAction(state: PermissionState, action: PermissionAction) {
    remove(state.permisssionActions, (item) =>
      item.resourcePermissionId === action.resourcePermissionId &&
      item.applicationId === action.applicationId);
  },
  clearActions(state: PermissionState) {
    state.permisssionActions = [];
  }
};
