import { UserManagerSettings } from "oidc-client";
import { identityUrl, clientId, uiUrl } from "@/Config";

export const authName = "main";

export const scopes = [
    "openid",
    "profile",
    "role",
    "organization_id",
    "auth_api",
    "cfg_idp.mng",
    "cfg_org.mng",
    "cfg_grp.mng",
    "cfg_usr.mng",
    "cfg_app.mng",
    "cfg_apptype.mng",
    "resource.mng",
    "menu.mng"
];

export const settings: UserManagerSettings = {
    authority: identityUrl,
    client_id: clientId,
    response_type: "code",
    scope: scopes.join(" "),
    silentRequestTimeout: 15000,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    post_logout_redirect_uri: uiUrl,
    redirect_uri: `${uiUrl}/callback`,
    silent_redirect_uri: `${uiUrl}/silent.html`
};
